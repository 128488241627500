export const MAIN = '/'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'
export const PRIVACY_PARTNER = '/partner_privacy'
export const ABOUT = '/about'
export const FORGOT_PASSWORD = '/forgot'
export const UPDATE_PASSWORD = '/update-password'
export const PRICING = '/pricing'
export const PRICING_FAQ = `${PRICING}#faq`
export const FAQ = '/faq'
export const PACKAGE_FAQ = '/add-package/faq'
export const LOGIN = '/signin'
export const SIGNUP = '/signup'
export const ZIP_CODE = '/zip-code'
export const UNAVAILABLE_AREA = '/unavailable-area'
export const UNAVAILABLE_AREA_THANKS = '/unavailable-area-ty'
export const SCHEDULE_PICKUP_SUCCESS = '/schedule-pickup-success'
export const MY_ACCOUNT = '/my-account'
export const MY_ACCOUNT_SCHEDULE_PICKUP = `${MY_ACCOUNT}/schedule-pickup`
export const MY_ACCOUNT_PROFILE = `${MY_ACCOUNT}/profile`
export const DASHBOARD = `${MY_ACCOUNT}/dashboard`
export const MY_ACCOUNT_SECURITY = `${MY_ACCOUNT}/security`
export const UPGRADE = `${MY_ACCOUNT}/upgrade`
export const FIRST_PICKUP = '/first-pickup'
export const FIRST_PICKUP_DATE = '/date'
export const FIRST_PICKUP_ACCOUNT = '/account'
export const FIRST_PICKUP_DETAILS = '/details'
export const FIRST_PICKUP_INFO = '/info'
export const FIRST_PICKUP_REVIEW = '/review'
export const OAUTH = '/oauth'
export const SUPPORT = '/support'
export const SWAP = '/swap/:tripId'
export const BLOGS = '/blog'
export const BLOG = '/blog/:blogId'
export const TRACKING = '/tracking'
export const PACKAGE = '/package'
export const TRACKING_ID = '/tracking/:trackingId'
export const TRIPS_TRACKING = '/trip'
export const TRIPS_TRACKING_ID = '/trip/:trackingId'
export const FOR_BRANDS = '/for-brands'
export const HOME = '/home'
export const DRIVE = '/drive'
export const MANIFEST = '/manifest/:tripId'
export const CAREER = '/career'
export const LUXERONE = '/luxerone'
export const LUXERONE_FREE = '/luxerone_free'
export const LEXERONE_TRIAL = '/luxerone_trial'
export const CONSOLIDATIONS_DETAILS = '/consolidation/:consolidationId'
export const CONSOLIDATION = '/consolidation'
export const SHIPSWAY_URL = 'https://www.shipsway.com/'

export const PROMO_RTR = '/rtr'
export const PROMO_MAIL = '/mail'

export const LITTLE_MOBILE_PATHS = [
  {
    path: PRICING_FAQ,
    text: 'FAQ',
  },
  {
    path: LOGIN,
    text: 'Sign in',
  },
]

export const DISABLED_PATH_FOR_POPUP = [CAREER]

export const PATH_FOR_REDIRECT_TO_SWAY = [FOR_BRANDS, MAIN]
